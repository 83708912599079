import styled from 'styled-components';

export const ModalEditarNomeCategoriaContainer = styled.div`

`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 2rem;
    width: 40vw;

    > h1 {
        margin-bottom: 1rem;
        color: #000;
        font-weight: 600;
        font-size: 1.3rem;
    }
    
    > input {
        /* background-color: #F0F0F0; */
        border-radius: 6px;
        width: 100%;
        padding-left: 10px;
        height: 44px;
        border: none;
        color: #292828;
    }

    > .buttons-container {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
        width: 100%;
        > .voltar {
            border: none;
            background: none;
            color: #292828;
            text-decoration: underline;
        }
    }
`;

export const SalvarButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    width: 159px;
    height: 42px;
    border-radius: 20px;
    background: #FDBC11;
    border: none;
    color: #292828;
    font-weight: 600;
`;
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../providers/Auth';
import pedidoSchema from '../../../schemas/Pedidos/pedidoSchema';
import api from '../../../services/api';
import Loading from '../../Loading';
import CustomModal from '../CustomModal';
import { ContentContainer, Form, InputContainer, ModalEditarPedidoInformacoesContainer, SalvarButton, SelectContainer } from './styles';
import Select from 'react-select';
import { ReactComponent as SalvarSvg } from '../../../assets/salvar.svg';
import { ReactSVG } from 'react-svg';
import InformacoesSvg from '../../../assets/informacoes.svg';
import { toast } from 'react-toastify';

const ModalEditarPedidoInformacoes = ({ setModalOpened, pedido, buscarPedidoIncompleto }) => {
    const { register, handleSubmit, formState: { errors }, setValue, reset, watch, control } = useForm({
        resolver: yupResolver(pedidoSchema)
    });

    //     const handlePedidoChange = (newValue) => {
    //    
    //     if (newValue === 'impresso' || newValue === 'qrcode' || newValue === 'impresso e qrcode') {
    //         setValue('quantidade', 0);
    //     }
    // };


    // const [showQuantidade, setShowQuantidade] = React.useState(true);

  
    // const handlePedidoChange = (newValue) => {
    //     if (newValue === 'impresso' || newValue === 'qrcode' || newValue === 'impresso e qrcode') {
    //         setShowQuantidade(false);
    //         setValue('quantidade', 0); 
    //     } else {
    //         setShowQuantidade(true);
    //     }
    // };

    

    const [opcaoTipo, setOpcaoTipo] = useState([]);
    const [opcaoTipoSelecionado, setopcaoTipoSelecionado] = useState([]);
    const [opcaoQuantidade,setOpcaoQuantidade] = useState([]);
    const [opcaoQuantidadeQrCode,setOpcaoQuantidadeQrCode] = useState([]);
    const [opcaoTemplate,setOpcaoTemplate] = useState([]);
            
    const [operacoesOptions, setOperacoesOptions] = useState([]);
    const [operacaoSelecionada, setOperacaoSelecionada] = useState('');
    const [pdvsOptions, setPdvsOptions] = useState([]);
    const [pdvLoading, setPdvLoading] = useState(false);
    
    const [loading, setLoading] = useState(true);
    const [operacoesLoaded, setOperacoesLoaded] = useState(false);
    const [qrcode, setQrcode] = useState('');

    const { logout,user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (operacoesLoaded) {
            setLoading(false);
        }
    }, [operacoesLoaded])

    useEffect(() => {

        setOpcaoQuantidadeQrCode(
            [
                {id:10,valor:10,nome:10},
                {id:20,valor:20,nome:20},
                {id:30,valor:30,nome:30},
                {id:40,valor:40,nome:40},
                {id:50,valor:50,nome:50}
            ])

    },[])

    useEffect(() => {
        //opcão da quantidade de impressos e template
        if(user.geo_id === 1){
            setOpcaoTipo([
                {id: 1, valor: 'verso', nome: 'Frente e Verso C/ Capa'},
                {id: 2, valor: 'frenteVerso', nome: 'Frente e Verso S/ Capa'},
            ])
            setOpcaoQuantidade(
            [
                {id:10,valor:10,nome:10},
                {id:20,valor:20,nome:20},
                {id:30,valor:30,nome:30},
                {id:40,valor:40,nome:40},
                {id:50,valor:50,nome:50}
            ])
            // setOpcaoQuantidadeQrCode([
            //     {id:10,valor:10,nome:10},
            //     {id:20,valor:20,nome:20},
            //     {id:30,valor:30,nome:30},
            //     {id:40,valor:40,nome:40},
            //     {id:50,valor:50,nome:50}

            // ])
            setOpcaoTemplate([
                {id:1,valor:"brahma",nome:"Brahma"},
                {id:2,valor:"brahma litrinho",nome:"Brahma Litrinho"},
                {id:3,valor:"brahma duplo malte",nome:"Brahma Duplo Malte"},
                {id:4,valor:"spaten",nome:"Spaten"},
                {id:5,valor:"budweiser",nome:"Budweiser"}
            ])
        }
        else if(user.geo_id === 3){
            setOpcaoTipo([
                {id: 1, valor: 'verso', nome: 'Frente e Verso C/ Capa'},
                {id: 2, valor: 'frenteVerso', nome: 'Frente e Verso S/ Capa'},
                {id: 3, valor: 'paginaTodaSemCapa', nome: 'Frente e Verso - Coluna Unica'},
                {id: 4, valor: 'misto', nome: 'Frente e Verso Misto - S/ Capa x Coluna Unica'},
                {id: 5, valor: 'mistoPaginaToda', nome: 'Frente e Verso Misto - Coluna Unica x S/ Capa'},
            ])
            setOpcaoQuantidade(
            [
                {id:10,valor:10,nome:10},
                {id:11,valor:11,nome:11},
                {id:12,valor:12,nome:12},
                {id:13,valor:13,nome:13},
                {id:14,valor:14,nome:14},
                {id:15,valor:15,nome:15},
                {id:16,valor:16,nome:16},
                {id:17,valor:17,nome:17},
                {id:18,valor:18,nome:18},
                {id:19,valor:19,nome:19},
                {id:20,valor:20,nome:20},
                // {id:21,valor:21,nome:21},
                // {id:22,valor:22,nome:22},
                // {id:23,valor:23,nome:23},
                // {id:24,valor:24,nome:24},
                // {id:25,valor:25,nome:25},
                // {id:26,valor:26,nome:26},
                // {id:27,valor:27,nome:27},
                // {id:28,valor:28,nome:28},
                // {id:29,valor:29,nome:29},
                // {id:30,valor:30,nome:30},
                // {id:31,valor:31,nome:31},
                // {id:32,valor:32,nome:32},
                // {id:33,valor:33,nome:33},
                // {id:34,valor:34,nome:34},
                // {id:35,valor:35,nome:35},
                // {id:36,valor:36,nome:36},
                // {id:37,valor:37,nome:37},
                // {id:38,valor:38,nome:38},
                // {id:39,valor:39,nome:39},
                // {id:40,valor:40,nome:40},
                // {id:41,valor:41,nome:41},
                // {id:42,valor:42,nome:42},
                // {id:43,valor:43,nome:43},
                // {id:44,valor:44,nome:44},
                // {id:45,valor:45,nome:45},
                // {id:46,valor:46,nome:46},
                // {id:47,valor:47,nome:47},
                // {id:48,valor:48,nome:48},
                // {id:49,valor:49,nome:49},
                // {id:50,valor:50,nome:50}
            ])
            // setOpcaoQuantidadeQrCode([
            //     {id:10,valor:10,nome:10},
            //     {id:11,valor:11,nome:11},
            //     {id:12,valor:12,nome:12},
            //     {id:13,valor:13,nome:13},
            //     {id:14,valor:14,nome:14},
            //     {id:15,valor:15,nome:15},
            //     {id:16,valor:16,nome:16},
            //     {id:17,valor:17,nome:17},
            //     {id:18,valor:18,nome:18},
            //     {id:19,valor:19,nome:19},
            //     {id:20,valor:20,nome:20},

            // ])
            setOpcaoTemplate([
                {id:1,valor:"brahma",nome:"Brahma"},
                {id:2,valor:"brahma duplo malte",nome:"Brahma Duplo Malte"},
                {id:4,valor:"budweiser",nome:"Budweiser"},
                {id:5,valor:"original",nome:"Original"},
                // {id:3,valor:"spaten",nome:"Spaten"},
                // {id:6,valor:"bohemia",nome:"Bohemia"},
                // {id:7,valor:"skol",nome:"Skol"},
                // {id:8,valor:"stella",nome:"Stella"},
            ])
        }
        else if (user.geo_id === 4) {
            setOpcaoTipo([
                {id: 1, valor: 'verso', nome: 'Frente e Verso C/ Capa'},
            ])
            setOpcaoQuantidade(
            [
                {id:10,valor:10,nome:10},
                {id:11,valor:11,nome:11},
                {id:12,valor:12,nome:12},
                {id:13,valor:13,nome:13},
                {id:14,valor:14,nome:14},
                {id:15,valor:15,nome:15},
                {id:16,valor:16,nome:16},
                {id:17,valor:17,nome:17},
                {id:18,valor:18,nome:18},
                {id:19,valor:19,nome:19},
                {id:20,valor:20,nome:20},
                {id:21,valor:21,nome:21},
                {id:22,valor:22,nome:22},
                {id:23,valor:23,nome:23},
                {id:24,valor:24,nome:24},
                {id:25,valor:25,nome:25},
                {id:26,valor:26,nome:26},
                {id:27,valor:27,nome:27},
                {id:28,valor:28,nome:28},
                {id:29,valor:29,nome:29},
                {id:30,valor:30,nome:30},
                {id:31,valor:31,nome:31},
                {id:32,valor:32,nome:32},
                {id:33,valor:33,nome:33},
                {id:34,valor:34,nome:34},
                {id:35,valor:35,nome:35},
                {id:36,valor:36,nome:36},
                {id:37,valor:37,nome:37},
                {id:38,valor:38,nome:38},
                {id:39,valor:39,nome:39},
                {id:40,valor:40,nome:40},
                {id:41,valor:41,nome:41},
                {id:42,valor:42,nome:42},
                {id:43,valor:43,nome:43},
                {id:44,valor:44,nome:44},
                {id:45,valor:45,nome:45},
                {id:46,valor:46,nome:46},
                {id:47,valor:47,nome:47},
                {id:48,valor:48,nome:48},
                {id:49,valor:49,nome:49},
                {id:50,valor:50,nome:50}
            ])
            setOpcaoTemplate([
                {id:1,valor:"brahma",nome:"Brahma"},
                {id:2,valor:"brahma duplo malte",nome:"Brahma Duplo Malte"},
                {id:3,valor:"spaten",nome:"Spaten"},
                {id:4,valor:"budweiser",nome:"Budweiser"},
                {id:5,valor:"original",nome:"Original"},
                {id:6,valor:"stella",nome:"Stella"},
                {id:6,valor:"corona",nome:"Corona"},
                {id:6,valor:"guarana zero",nome:"Guaraná Zero"},
            ])
        }
        else if(![1,3,4].includes(user.geo_id)){
            setOpcaoTipo([
                {id: 1, valor: 'verso', nome: 'Frente e Verso C/ Capa'},
            ])
            setOpcaoQuantidade(
            [
                {id:10,valor:10,nome:10},
                {id:11,valor:11,nome:11},
                {id:12,valor:12,nome:12},
                {id:13,valor:13,nome:13},
                {id:14,valor:14,nome:14},
                {id:15,valor:15,nome:15},
                {id:16,valor:16,nome:16},
                {id:17,valor:17,nome:17},
                {id:18,valor:18,nome:18},
                {id:19,valor:19,nome:19},
                {id:20,valor:20,nome:20},
                {id:21,valor:21,nome:21},
                {id:22,valor:22,nome:22},
                {id:23,valor:23,nome:23},
                {id:24,valor:24,nome:24},
                {id:25,valor:25,nome:25},
                {id:26,valor:26,nome:26},
                {id:27,valor:27,nome:27},
                {id:28,valor:28,nome:28},
                {id:29,valor:29,nome:29},
                {id:30,valor:30,nome:30},
                {id:31,valor:31,nome:31},
                {id:32,valor:32,nome:32},
                {id:33,valor:33,nome:33},
                {id:34,valor:34,nome:34},
                {id:35,valor:35,nome:35},
                {id:36,valor:36,nome:36},
                {id:37,valor:37,nome:37},
                {id:38,valor:38,nome:38},
                {id:39,valor:39,nome:39},
                {id:40,valor:40,nome:40},
                {id:41,valor:41,nome:41},
                {id:42,valor:42,nome:42},
                {id:43,valor:43,nome:43},
                {id:44,valor:44,nome:44},
                {id:45,valor:45,nome:45},
                {id:46,valor:46,nome:46},
                {id:47,valor:47,nome:47},
                {id:48,valor:48,nome:48},
                {id:49,valor:49,nome:49},
                {id:50,valor:50,nome:50}
            ])
            setOpcaoTemplate([
                {id:1,valor:"brahma",nome:"Brahma"},
                {id:2,valor:"brahma duplo malte",nome:"Brahma Duplo Malte"},
                {id:3,valor:"spaten",nome:"Spaten"},
                {id:4,valor:"budweiser",nome:"Budweiser"},
                {id:5,valor:"original",nome:"Original"},
                {id:6,valor:"stella",nome:"Stella"}
            ])
        }

        //BUSCA DE OPERAÇÕES
        const buscarOperacoes = () => {
            api.get('/operacoes')
                .then((response) => {
                    const operacoesOptions = response.data.data.operacoes.map((operacao) => {
                        return {
                            value: operacao.id,
                            label: operacao.nome
                        }
                    });
                    setOperacoesOptions(operacoesOptions);
                    setOperacoesLoaded(true);
                }).catch((error) => {
                    if (error.response?.status === 401){
                        logout();
                        navigate('/');
                    };
                });
        };

        buscarOperacoes();
        if (pedido) {
            reset(pedido);
            let tipoPedido = '';
            if (Number(pedido.impresso) && Number(pedido.qrcode)) {
                tipoPedido = 'impresso e qrcode';
            } else if (Number(pedido.qrcode)) {
                tipoPedido = 'qrcode';
            } else if (Number(pedido.impresso)) {
                tipoPedido = 'impresso';
            } else {
                tipoPedido = 'digital';
            } 
            setValue('pedido', tipoPedido);
            setOperacaoSelecionada({ value: pedido.operacao_id, label: pedido.nome_operacao });
            setValue('ponto_de_venda_id', { value: pedido.ponto_de_venda_id, label: pedido.ponto_de_venda });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //AJUSTA OPÇÕES CONFORME O TIPO SELECIONADO
    useEffect(()=>{
        if(user.geo_id === 3 && opcaoTipoSelecionado === "verso"){
            setOpcaoTemplate([
                {id:1,valor:"brahma",nome:"Brahma"},
                {id:2,valor:"brahma duplo malte",nome:"Brahma Duplo Malte"},
                {id:3,valor:"spaten",nome:"Spaten"},
                {id:4,valor:"budweiser",nome:"Budweiser"},
                {id:5,valor:"original",nome:"Original"},
                // {id:6,valor:"bohemia",nome:"Bohemia"},
                // {id:7,valor:"skol",nome:"Skol"},
                // {id:8,valor:"stella",nome:"Stella"},
            ])
        }
        else if(user.geo_id === 3 && opcaoTipoSelecionado === "frenteVerso"){
            setOpcaoTemplate([
                {id:1,valor:"brahma",nome:"Brahma"},
                {id:2,valor:"brahma duplo malte",nome:"Brahma Duplo Malte"},
                {id:3,valor:"spaten",nome:"Spaten"},
                {id:4,valor:"budweiser",nome:"Budweiser"},
                {id:5,valor:"original",nome:"Original"},
                // {id:6,valor:"bohemia",nome:"Bohemia"},
                // {id:7,valor:"skol",nome:"Skol"},
                // {id:8,valor:"stella",nome:"Stella"},
            ])
        }
    },[opcaoTipoSelecionado])

    useEffect(() => {
        const buscarPDVs = () => {
            api.get('/pdvs')
                .then((response) => {
                    const pdvsFiltrados = response.data.data.pdvs.filter((pdv) => String(pdv.operacao_id) === String(operacaoSelecionada.value) && pdv.status === 1);
                    const pdvsOptions = pdvsFiltrados.map((pdv) => {
                        return {
                            value: pdv.id,
                            label: pdv.codigo + ' - ' + pdv.nome
                        }
                    });
                    setPdvsOptions(pdvsOptions);
                    setPdvLoading(false);
                }).catch((error) => {
                    if (error.response?.status === 401){
                        logout();
                        navigate('/');
                    };
                    setPdvLoading(false);
                });
        };
        if (operacaoSelecionada){
            setPdvsOptions([]);
            setPdvLoading(true);
            buscarPDVs();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operacaoSelecionada]);

    const onSubmitFunction = (data) => {
        const dados = {
            ...data,
            impresso: data.pedido.includes('impresso') ? '1' : '0',
            qrcode: data.pedido.includes('qrcode') ? '1' : '0',
            quantidade:data.pedido !== 'qrcode' ? data.quantidade : 0,
            quantidade_qrcode:data.pedido !== 'impresso' ? data.quantidade_qrcode : 0,
            ponto_de_venda_id: data.ponto_de_venda_id.value,
            operacao_id: operacaoSelecionada.value,
        }
        api.patch(`/pedidos/${pedido.id}`, dados)
            .then((response) => {
                buscarPedidoIncompleto();
                setModalOpened(false);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                if (error.response?.status === 409) {
                    toast.error('Usuário com pedido em andamento.');
                    return;
                };

                toast.error('Erro ao criar pedido');
            });
    };

    return (
        <ModalEditarPedidoInformacoesContainer>
            <CustomModal setModalOpened={setModalOpened}>
                <ContentContainer>
                    {loading ? (
                        <Loading />
                    ) : (
                        <Form onSubmit={handleSubmit(onSubmitFunction)}>
                            <div className='title'>
                                <h1>
                                    <ReactSVG src={InformacoesSvg} /> Editar informações
                                </h1>
                            </div>
                            <SelectContainer className='operacao'>
                                <label htmlFor='operacao'>Operação</label>
                                <Controller 
                                    name='operacao'
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={operacoesOptions}
                                            defaultValue={null}
                                            className='react-select-container'
                                            classNamePrefix='react-select'
                                            value={operacaoSelecionada}
                                            placeholder='Selecione a operação'
                                            onChange={(value) => {
                                                setOperacaoSelecionada(value);
                                                setValue('ponto_de_venda_id', null);
                                            }}
                                        /> 
                                    )}
                                />
                            </SelectContainer>
                            <SelectContainer className='pdv'>
                                <label htmlFor='pdv'>PDV</label>
                                <Controller 
                                    name='ponto_de_venda_id'
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={pdvsOptions}
                                            defaultValue={null}
                                            className='react-select-container'
                                            classNamePrefix='react-select'
                                            isLoading={pdvLoading}
                                            isDisabled={!operacaoSelecionada}
                                            placeholder={operacaoSelecionada ? 'Selecione o PDV' : 'Selecione uma operação'}
                                        /> 
                                    )}
                                />
                                {errors.ponto_de_venda_id && <span className='input-error'>{errors.ponto_de_venda_id.message}</span>}
                            </SelectContainer>
                            <InputContainer className='titulo'>
                                <label htmlFor='titulo'>Título</label>
                                <input
                                    type='text'
                                    id='titulo'
                                    placeholder='Título do pedido'
                                    {...register('titulo')}
                                />
                                {errors.titulo && <span className='input-error'>{errors.titulo.message}</span>}
                            </InputContainer>
                            <SelectContainer className='tipo'>
                                <label htmlFor='tipo'>Tipo</label>
                                <select
                                    name='tipo'
                                    id='tipo'
                                    {...register('tipo')}
                                    onChange={(value)=>{setopcaoTipoSelecionado(value.target.value)}}

                                >
                                    <option value=''>Selecione o tipo</option>
                                    {opcaoTipo.map((item)=> <option key={item.id} value={item.valor}>{item.nome}</option>)}
                                </select>
                                {errors.tipo && <span className='input-error'>{errors.tipo.message}</span>}
                            </SelectContainer>
                            <SelectContainer className='pedido'>
                                <label htmlFor='pedido'>Tipo de pedido</label>
                                <select
                                    name='pedido'
                                    id='pedido'
                                    {...register('pedido')}
                                    // onChange={(e) => {
                                    //     const newValue = e.target.value;
                                    //     handlePedidoChange(newValue);
                                    // }}
                                >
                                   
                                    <option value='' title='Selecione o tipo de pedido'>Selecione o tipo de pedido</option>
                                    <option value='impresso' title='Cardápio Impresso'>Cardápio Impresso</option>
                                    <option value='qrcode' title='QR Code Impresso'>QR Code Impresso</option>
                                    <option value='impresso e qrcode' title='Cardápio e QR Code Impresso'>Cardápio e QR Code Impresso</option>
                                    <option value='digital' title='Atualizar informações do ultimo pedido'>Atualizar cardápio digital</option>
                                </select>
                                {errors.pedido && <span className='input-error'>{errors.pedido.message}</span>}
                            </SelectContainer>
                            {watch('pedido') !== 'qrcode' && (
                            <SelectContainer className='quantidade'>
                                <label htmlFor='quantidade'>Quantidade de impresso</label>
                                <select
                                    name='quantidade'
                                    id='quantidade'
                                    {...register('quantidade')}
                                >
                                    <option key={0} value={0}>Selecione a quantidade</option>
                                    {opcaoQuantidade.map((item)=> <option key={item.id} value={item.valor}>{item.nome}</option>)}
                                </select>
                                {errors.quantidade && <span className='input-error'>{errors.quantidade.message}</span>}
                            </SelectContainer>
                            )}
                           
                            {watch('pedido') !== 'impresso' && watch('pedido') !== 'digital' && (
                            <SelectContainer className='quantidade'>
                                <label htmlFor='quantidade_qrcode'>Quantidade de QR Code</label>
                                <select
                                    name='quantidade_qrcode'
                                    id='quantidade_qrcode'
                                    {...register('quantidade_qrcode')}
                                    onChange={(e)=> setQrcode(e.target.value)}   
                                >
                                    <option key={0} value={0}>Selecione a Quantidade QRcode</option>
                                    {opcaoQuantidadeQrCode.map((item) => (
                                        <option key={item.id} value={item.valor}>{item.nome}</option>
                                    ))}
                                </select>
                                {errors.quantidade_qrcode && <span className='input-error'>{errors.quantidade_qrcode.message}</span>}
                            </SelectContainer>
                        )}
                            <SelectContainer className='marca'>
                                <label htmlFor='marca'>Marca customizada</label>
                                <select
                                    name='marca'
                                    id='marca'
                                    {...register('template')}
                                >
                                    <option key={0} value=''>Selecione a marca de customização</option>
                                    {opcaoTemplate.map((item)=> <option key={item.id} value={item.valor}>{item.nome}</option>)}
                                </select>
                                {errors.template && <span className='input-error'>{errors.template.message}</span>}
                            </SelectContainer>


                            <div className='buttons-container'>
                                <button 
                                    className='voltar' 
                                    onClick={() => setModalOpened(false)}
                                    type='button'
                                >
                                    {'< Voltar'}
                                </button>
                                <SalvarButton
                                    type='submit'
                                >
                                    <SalvarSvg /> Salvar
                                </SalvarButton>
                            </div>
                        </Form>
                    )}
                </ContentContainer>
            </CustomModal>
        </ModalEditarPedidoInformacoesContainer>
    );
};

export default ModalEditarPedidoInformacoes;
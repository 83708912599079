import styled from 'styled-components';

export const PedidoCategoriaProdutosContainer = styled.div`
    margin: 1rem 0;
`;

export const TitleContainer = styled.div`
    display: flex;

    background: #FDBC11;
    border-radius: 6px;
    padding: 10px 0;

    position: relative;

    > button:first-child {
        margin-left: 10px;
    }

    > button {
        display: flex;
        align-items: center;
        justify-content: center;

        color: #292828;
        background: none;
        border: 2px solid #292828;
        border-radius: 6px;
        margin: 0 0 0 5px;
        width: 23px;
        height: 23px;

        :disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    > p {
        color: #292828;
        font-weight: 700;
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: flex;

        > .editar {
            width: 13px;
            height: 15px;
            margin-left: 4px;
            border-bottom: 1px solid #292828;
            cursor: pointer;
            
            > div {
                width: 100%;
                height: 100%;
                svg {
                    width: 100%;
                    height: 100%;
                    path, polygon, rect, circle, ellipse {
                        fill: #292828;    
                    }
                }
            }
        }
    }

    > .sort-itens-container {
        display: flex;
        margin-left: auto;
        margin-right: 15px;
        column-gap: 10px;

        > button {
            display: flex;
            align-items: center;
            justify-content: center;

            color: #292828;
            border: none;
            background: none;

            :disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }
    }
`;

export const FormContainer = styled.form`
    display: flex;
    column-gap: 1%;
    margin: 5px 0 10px;

    > .nome {
        width: 35%;
    }

    > .volume {
        width: 28%;
    }

    > .preco {
        width: 26%;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        row-gap: 5px;

        > .nome,
        > .volume,
        > .preco {
            width: 100%;
        }
    }
`;

export const InputContainer = styled.div`
    display: flex;
    align-items: center;
    row-gap: 5px;
    background-color: #F0F0F0;
    color: #292828;
    padding-left: 10px;
    position: relative;

    > label {
        color: #292828;
    }

    > input {
        background: transparent;
        border-radius: 6px;
        width: 100%;
        height: 38px;
        border: none;
        color: #292828;

        :focus {
            outline: none;
        }
    }

    > span {
        top: 32px;
    }
`;

export const AdicionarButton = styled.button`
    background: none;   
    border: 1px solid #292828;
    color: #292828;
    border-radius: 20px;
    height: 38px;
    padding: 0 15px;
`;

export const TableContainer = styled.div`
    > table > thead > tr > :nth-child(1) {
        text-align: center;
    }
    @media screen and (max-width: 840px) {
        > table {
            > thead {
                display: none;
            }
        }
    }
`;
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import operacaoSchema from '../../../schemas/Operacoes/operacaoSchema';
import { CheckboxContainer, Form, InputContainer, SalvarButton, SelectContainer } from './styles';
import { ReactComponent as SalvarSvg } from '../../../assets/salvar.svg';
import ImportarSvg from '../../../assets/importar.svg';
import { UFs } from '../../../utils/states';
import { useAuth } from '../../../providers/Auth';
import api from '../../../services/api';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ReactSVG } from 'react-svg';
import ModalImportarOperacoes from '../../Modals/ModalImportarOperacoes';
import Loading from '../../Loading';
import Select from 'react-select';

const OperacaoForm = () => {
    const { register, handleSubmit, formState: { errors }, reset, control } = useForm({
        resolver: yupResolver(operacaoSchema)
    });

    const [comerciaisOptions, setComerciaisOptions] = useState([]);

    const [modalImportarOperacoes, setModalImportarOperacoes] = useState(false);

    const { operacao_id } = useParams();
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const [operacaoLoaded, setOperacaoLoaded] = useState(false);
    const [comerciaisLoaded, setComerciaisLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (operacaoLoaded && comerciaisLoaded) {
            setLoading(false);
        };
    }, [operacaoLoaded, comerciaisLoaded]);

    const buscarComerciais = () => {
        api.get(`/comerciais`)
            .then(response => {
                const comerciaisFiltrados = response.data.data.comerciais.filter(comercial => comercial.status);
                const options = comerciaisFiltrados.map(comercial => {
                    return {
                        value: comercial.id,
                        label: comercial.nome,
                        geo_id: comercial.geo_id
                    };
                });
                setComerciaisOptions(options);
                setComerciaisLoaded(true);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/');
                    return;
                };
                if (error.response.status === 403) {
                    navigate('/pedidos');
                    return;
                };
                toast.error('Erro ao buscar Comercial!');
            });
    };

    useEffect(() => {
        if (operacao_id) {
            api.get(`/operacoes/${operacao_id}`)
                .then(response => {
                    const operacao = response.data.data.operacao;
                    operacao.estado = {
                        value: operacao.estado,
                        label: operacao.estado 
                    };
                    operacao.comercial = {
                        value: operacao.comercial_id,
                        label: operacao.comercial,
                    };
                    reset(operacao);
                    setOperacaoLoaded(true);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response.status === 403) {
                        navigate('/pedidos');
                        return;
                    }
                    if (error.response.status === 404) {
                        toast.error('Operação não encontrada!');
                        return;
                    };
                    toast.error('Erro ao buscar Operação!');
                });
        } else {
            setOperacaoLoaded(true);
        };
        buscarComerciais();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmitFunction = (data) => {
        setLoading(true);
        const dados = {
            ...data,
            estado: data.estado.value,
            comercial_id: data.comercial.value,
            status: data.status ? '1' : '0',
        };
        if (operacao_id) {
            dados.id = operacao_id;
            api.patch(`/operacoes`, {operacoes: [dados]})
                .then(response => {
                    toast.success('Operação editada com sucesso!');
                    navigate('/administrativo/operacao');
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        logout();
                        navigate('/')
                        return;
                    };
                    if (error.response.status === 409) {
                        if (error.response.data.data?.operacao) {
                            const message = error.response.data.message;
                            toast.error(`${message}`);
                            setLoading(false);
                            return;
                        }
                        toast.error('Operação já cadastrada!');
                        setLoading(false);
                        return;
                    };
                    toast.error('Erro ao editar Operação!');
                    setLoading(false);
                });
            return;
        };

        api.post('/operacoes', {operacoes: [dados]})
            .then(response => {
                reset();
                toast.success('Operação cadastrada com sucesso!');
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    logout();
                    return;
                };
                if (error.response.status === 409) {
                    if (error.response.data.data?.operacao) {
                        const message = error.response.data.message;
                        toast.error(`${message}`);
                        return;
                    }
                    toast.error('Operação já cadastrada!');
                    return;
                };
                toast.error('Erro ao cadastrar Operação!');
                setLoading(false);
            });
    };

    return (
        <>
        {loading ? (
            <Loading />
        ) : (
            <Form onSubmit={handleSubmit(onSubmitFunction)}>
                {!operacao_id && (
                    <div className='importar-container'>
                        <button onClick={() => setModalImportarOperacoes(true)} type='button'>
                        <ReactSVG src={ImportarSvg}/> importar arquivo
                        </button>
                    </div>
                )}
                <CheckboxContainer className='ativo'>
                    <input
                        type='checkbox'
                        id='ativo'
                        {...register('status')}
                    />
                    <label htmlFor='ativo'>Ativo</label>
                </CheckboxContainer>
                <InputContainer className='nome'>
                    <label htmlFor='nome'>Nome</label>
                    <input 
                        type='text'
                        id='nome'
                        placeholder='Nome da operação'
                        {...register('nome')}
                    />
                    {errors.nome && <span className='input-error' style={{top: '60px'}}>{errors.nome.message}</span>}
                </InputContainer>
                <InputContainer className='tipo'>
                    <label htmlFor='tipo'>Tipo</label>
                    <input
                        type='text'
                        id='tipo'
                        placeholder='CDD, Franquia, Revenda, etc.'
                        {...register('tipo')}
                    />
                    {errors.tipo && <span className='input-error' style={{top: '60px'}}>{errors.tipo.message}</span>}
                </InputContainer>
                <InputContainer className='codigo'>
                    <label htmlFor='codigo'>Código</label>
                    <input
                        type='text'
                        id='codigo'
                        placeholder='99815014'
                        {...register('codigo')}
                    />
                    {errors.codigo && <span className='input-error' style={{top: '60px'}}>{errors.codigo.message}</span>}
                </InputContainer>
                <SelectContainer className='comercial'>
                    <label htmlFor='comercial'>Comercial</label>
                    <Controller 
                        name='comercial'
                        control={control}
                        render={({ field }) => (
                            <Select
                                {...field}
                                options={comerciaisOptions}
                                defaultValue={null}
                                isClearable
                                className='react-select-container'
                                classNamePrefix='react-select'
                                placeholder='Selecione o comercial'
                            /> 
                        )}
                    />
                    {errors.comercial && <span className='input-error' style={{top: '60px'}}>{errors.comercial.message}</span>}
                </SelectContainer>
                <SelectContainer className='estado'>
                    <label htmlFor='estado'>Estado</label>
                    <Controller
                        name='estado'
                        control={control}
                        render={({ field }) => (
                            <Select
                                {...field}
                                options={UFs.map(item => { return {value: item.uf, label: `${item.uf} - ${item.estado}`}})}
                                defaultValue={null}
                                isClearable
                                className='react-select-container'
                                classNamePrefix='react-select'
                                placeholder='Selecione o estado'
                            />
                        )}
                    />
                    {errors.estado && <span className='input-error' style={{top: '60px'}}>{errors.estado.message}</span>}
                </SelectContainer>
                <div className='buttons-container'>
                    <Link to='/administrativo/operacao'>{'< Voltar'}</Link>
                    <SalvarButton type='submit'>
                        <SalvarSvg /> Salvar
                    </SalvarButton>
                </div>
                {modalImportarOperacoes && (
                    <ModalImportarOperacoes setModalOpened={setModalImportarOperacoes} />
                )}
            </Form>
        )}
        </>
    );
};

export default OperacaoForm;
import { useState } from 'react';
import CustomModal from '../CustomModal/index.js';
import { ContentContainer, ModalEditarNomeCategoriaContainer, SalvarButton } from './styles.js';
import Loading from '../../Loading/index.js';
import { ReactComponent as SalvarSvg } from '../../../assets/salvar.svg';
import api from '../../../services/api.js';
import { toast } from 'react-toastify';

const ModalEditarNomeCategoria = ({ setModalOpened, categoria, pedido, buscarPedidoProdutoCategorias}) => {
    const [loading, setLoading] = useState(false);
    const [nomeCategoria, setNomeCategoria] = useState(categoria.label);
    
    const handleEditarNomeCategoria = () => {
        setLoading(true);
        api.patch(`/pedido-produto-categorias/${pedido.id}`, {
            categorias: [
                {
                    id: categoria.pedido_produto_categoria_id,
                    nome: nomeCategoria
                },
            ]
        }).then(() => {
            toast.success('Nome da categoria editado com sucesso!');
            buscarPedidoProdutoCategorias();
            setModalOpened(false);
        }).catch(() => {
            toast.error('Erro ao editar nome da categoria');
            setLoading(false);
        });
    };

    return (
        <ModalEditarNomeCategoriaContainer>
            <CustomModal setModalOpened={setModalOpened}>
                <ContentContainer>
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <h1>Editar nome da categoria</h1>
                            <input
                                type="text"
                                value={nomeCategoria}
                                onChange={(e) => setNomeCategoria(e.target.value)}
                            />
                            <div className='buttons-container'>
                                <button 
                                    className='voltar' 
                                    onClick={() => setModalOpened(false)}
                                    type='button'
                                >
                                    {'< Voltar'}
                                </button>
                                <SalvarButton
                                    type='submit'
                                    onClick={handleEditarNomeCategoria}
                                >
                                    <SalvarSvg /> Salvar
                                </SalvarButton>
                            </div>
                        </>
                    )}
                </ContentContainer>
            </CustomModal>
        </ModalEditarNomeCategoriaContainer>
    )
};

export default ModalEditarNomeCategoria;
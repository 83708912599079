import { Link, useNavigate, useParams } from 'react-router-dom';
import { CheckboxContainer, Form, InputContainer, SalvarButton, SelectContainer } from './styles';
import { ReactComponent as SalvarSvg } from '../../../assets/salvar.svg';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../providers/Auth';
import usuarioSchema from '../../../schemas/Usuarios/usuarioSchema';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Loading from '../../Loading';
import { ReactSVG } from 'react-svg';
import ImportarSvg from '../../../assets/importar.svg';
import ModalImportarUsuarios from '../../Modals/ModalImportarUsuarios';

const UsuarioForm = () => {
    const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm({
        resolver: yupResolver(usuarioSchema)
    });

    const [loading, setLoading] = useState(true);
    const { usuario_id } = useParams();
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const [geos, setGeos] = useState([]);
    const [geoSelecionada, setGeoSelecionada] = useState('');
    const [geoError, setGeoError] = useState(false);

    const [senha, setSenha] = useState('');
    const [senhaError, setSenhaError] = useState(false);

    const [operacoes, setOperacoes] = useState([]);
    const [operacoesFiltradas, setOperacoesFiltradas] = useState([]);
    const [operacoesSelecionadas, setOperacoesSelecionadas] = useState([]);
    const [operacoesVinculadas, setOperacoesVinculadas] = useState([]);

    const [rns, setRns] = useState([]);
    const [rnsSelecionados, setRnsSelecionados] = useState([]);
    const [rnsVinculados, setRnsVinculados] = useState([]);

    const [gvs, setGvs] = useState([]);
    const [gvsSelecionados, setGvsSelecionados] = useState([]);
    const [gvsVinculados, setGvsVinculados] = useState([]);   

    const [modalImportarUsuarios, setModalImportarUsuarios] = useState(false);
    
    const [usuarioLoaded, setUsuarioLoaded] = useState(false);
    const [geosLoaded, setGeosLoaded] = useState(false);
    const [operacoesLoaded, setOperacoesLoaded] = useState(false);
    const [operacoesVinculadasLoaded, setOperacoesVinculadasLoaded] = useState(false);
    const [rnsLoaded, setRnsLoaded] = useState(false);
    const [rnsVinculadosLoaded, setRnsVinculadosLoaded] = useState(false);
    const [gvsLoaded, setGvsLoaded] = useState(false);
    const [gvsVinculadosLoaded, setGvsVinculadosLoaded] = useState(false);

    useEffect(() => {
        if (
            usuarioLoaded
            &&
            geosLoaded
            &&
            operacoesLoaded
            &&
            operacoesVinculadasLoaded
            &&
            rnsLoaded
            &&
            rnsVinculadosLoaded
            &&
            gvsLoaded
            &&
            gvsVinculadosLoaded
        ) {
            setLoading(false);
        }
    },[usuarioLoaded, geosLoaded, operacoesLoaded, operacoesVinculadasLoaded, rnsLoaded, rnsVinculadosLoaded, gvsLoaded, gvsVinculadosLoaded]);

    const buscarGeos = () => {
        api.get(`/geos`)
            .then(response => {
                setGeos(response.data.data.geos);
                setGeosLoaded(true);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/');
                    return;
                };
                if (error.response.status === 403) {
                    navigate('/pedidos');
                    return;
                }
                toast.error('Erro ao buscar Geos!');
            });
    };

    const buscarOperacoes = () => {
        api.get('/operacoes')
            .then((response) => {
                const operacoesOptions = response.data.data.operacoes.map((operacao) => {
                    return {
                        value: operacao.id,
                        label: operacao.nome,
                        geo_id: operacao.geo_id
                    };
                });
                setOperacoes(operacoesOptions);
                setOperacoesLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    const buscarOperacoesVinculadas = () => {
        api.get(`/usuario-operacoes/${usuario_id}`)
            .then((response) => {
                const vinculos = response.data.data.vinculos;
                const operacoesSelecionadasOptions = vinculos.map((vinculo) => {
                    return {
                        value: vinculo.operacao_id,
                        label: vinculo.operacao_nome
                    };
                });
                setOperacoesVinculadas(vinculos);
                setOperacoesSelecionadas(operacoesSelecionadasOptions);
                setOperacoesVinculadasLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    const buscarUsuarios = () => {
        api.get('/usuarios')
            .then((response) => {
                const usuariosRns = response.data.data.usuarios.filter((usuario) => {
                    return usuario.permissao === 'rn';
                });
                const rnsOptions = usuariosRns.map((rn) => {
                    return {
                        value: rn.id,
                        label: rn.nome
                    };
                });
                setRns(rnsOptions);
                setRnsLoaded(true);
                const usuariosGvs = response.data.data.usuarios.filter((usuario) => {
                    return usuario.permissao === 'gv';
                });
                const gvsOptions = usuariosGvs.map((gv) => {
                    return {
                        value: gv.id,
                        label: gv.nome
                    };
                });
                setGvs(gvsOptions);
                setGvsLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    const buscarVinculosGvRn = () => {
        api.get(`/gv-rn/${usuario_id}`)
            .then((response) => {
                const vinculos = response.data.data.vinculos;
                const rnsSelecionadosOptions = vinculos.map((vinculo) => {
                    return {
                        value: vinculo.rn_id,
                        label: vinculo.nome_rn
                    };
                });
                const gvsSelecionadosOptions = vinculos.map((vinculo) => {
                    return {
                        value: vinculo.gv_id,
                        label: vinculo.nome_gv
                    };
                });
                setRnsVinculados(vinculos);
                setRnsSelecionados(rnsSelecionadosOptions);
                setRnsVinculadosLoaded(true);
                setGvsVinculados(vinculos);
                setGvsSelecionados(gvsSelecionadosOptions);
                setGvsVinculadosLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    useEffect(() => {
        const buscarUsuario = () => {
            api.get(`/usuarios/${usuario_id}`)
                .then(response => {
                    const usuario = response.data.data.usuario;
                    reset(usuario);
                    setGeoSelecionada(usuario.geo_id);
                    setUsuarioLoaded(true);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response.status === 403) {
                        navigate('/pedidos');
                        return;
                    }
                    if (error.response.status === 404) {
                        toast.error('Usuário não encontrado!');
                        return;
                    };
                    toast.error('Erro ao buscar usuário!');
                });
        };
        if (usuario_id) {
            buscarUsuario();
            buscarOperacoesVinculadas();
            buscarUsuarios();
            buscarVinculosGvRn();
        } else {
            setUsuarioLoaded(true);
            setOperacoesVinculadasLoaded(true);
            setRnsLoaded(true);
            setGvsLoaded(true);
            setRnsVinculadosLoaded(true);
            setGvsVinculadosLoaded(true);
        };
        buscarOperacoes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        if (user?.permissao === 'ac') {
            buscarGeos();
        } else {
            setGeosLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (operacoes.length) {
            const operacoesFiltradas = operacoes.filter((operacao) => {
                const filtroGeo = geoSelecionada ? String(operacao.geo_id) === String(geoSelecionada) : true;
                return filtroGeo;
            });
            setOperacoesFiltradas(operacoesFiltradas);
        };
    }, [operacoes, geoSelecionada]);

    const AdicionaOperacoes = async () => {
        const operacoesAdicionadas = operacoesSelecionadas.filter((operacao) => {
            return !operacoesVinculadas.some((vinculo) => {
                return operacao.value === vinculo.operacao_id;
            });
        });
        if (operacoesAdicionadas.length) {
            const dadosOperacoes = {
                usuario_id,
                operacoes_ids: operacoesAdicionadas.map((operacao) => {
                    return operacao.value;
                }),
            };
            await api.post('/usuario-operacoes', dadosOperacoes)
        };
    };

    const removeOperacoes = async () => {
        const operacoesRemovidas = operacoesVinculadas.filter((vinculo) => {
            return !operacoesSelecionadas.some((operacao) => {
                return operacao.value === vinculo.operacao_id;
            });
        });
        if (operacoesRemovidas.length) {
            const dadosOperacoesVinculos = {
                vinculos_ids: operacoesRemovidas.map((vinculo) => {
                    return vinculo.id;
                }),
            };
            await api.delete('/usuario-operacoes', {data: dadosOperacoesVinculos})
        };
    };

    const AdicionaRns = async () => {
        const rnsAdicionados = rnsSelecionados.filter((rn) => {
            return !rnsVinculados.some((vinculo) => {
                return rn.value === vinculo.rn_id;
            });
        });
        if (rnsAdicionados.length) {
            const dadosRns = {
                gv_id: usuario_id,
                rns_ids: rnsAdicionados.map((rn) => {
                    return rn.value;
                }),
            };
            await api.post('/gv-rn', dadosRns)   
        };
    };

    const removeRns = async () => {
        const rnsRemovidos = rnsVinculados.filter((vinculo) => {
            return !rnsSelecionados.some((rn) => {
                return rn.value === vinculo.rn_id;
            });
        });
        if (rnsRemovidos.length) {
            const dadosRnsVinculos = {
                vinculos_ids: rnsRemovidos.map((vinculo) => {
                    return vinculo.id;
                }),
            };
            await api.delete('/gv-rn', {data: dadosRnsVinculos})
        };
    };

    const AdicionaGvs = async () => {
        const gvsAdicionados = gvsSelecionados.filter((gv) => {
            return !gvsVinculados.some((vinculo) => {
                return gv.value === vinculo.gv_id;
            });
        });
        if (gvsAdicionados.length) {
            const dadosGvs = {
                rn_id: usuario_id,
                gvs_ids: gvsAdicionados.map((gv) => {
                    return gv.value;
                }),
            };
            await api.post('/gv-rn', dadosGvs)   
        };
    };

    const removeGvs = async () => {
        const gvsRemovidos = gvsVinculados.filter((vinculo) => {
            return !gvsSelecionados.some((gv) => {
                return gv.value === vinculo.gv_id;
            });
        });
        if (gvsRemovidos.length) {
            const dadosGvsVinculos = {
                vinculos_ids: gvsRemovidos.map((vinculo) => {
                    return vinculo.id;
                }),
            };
            await api.delete('/gv-rn', {data: dadosGvsVinculos})
        };
    };

    const onSubmitFunction = async (data) => {
        if ((user.permissao === 'ac' && !geoSelecionada) || (!senha && !usuario_id)) {
            return;
        };
        const dados = {
            ...data,
            geo_id: geoSelecionada,
            status: data.status ? '1' : '0',
        };
        setLoading(true);
        try {
            if (usuario_id) {
                dados.id = usuario_id;
                if (senha) {
                    dados.senha = senha;
                };
                await api.patch(`/usuarios`, {usuarios: [dados]});
            } else {
                dados.senha = senha;
                const response = await api.post('/usuarios', dados);
                if (operacoesSelecionadas.length) {
                    const dadosOperacoes = {
                        usuario_id: response.data.data.id,
                        operacoes_ids: operacoesSelecionadas.map((operacao) => {
                            return operacao.value;
                        }),
                    };
                    await api.post('/usuario-operacoes', dadosOperacoes)
                };
                toast.success('Usuário cadastrado com sucesso!');
                navigate(`/administrativo/usuario/editar/${response.data.data.id}`);
                return setLoading(false);
            };
            await AdicionaOperacoes();
            await removeOperacoes();
            if (dados.permissao_id === '4') {
                await AdicionaRns();
                await removeRns();
            };
            if (dados.permissao_id === '5') {
                await AdicionaGvs();
                await removeGvs();
            };
            setSenha('');
            toast.success('Usuário atualizado com sucesso!');
            setLoading(false);
        } catch (error) {
            if (error.response.status === 401) {
                logout();
                navigate('/');
                return;
            };
            if (error.response.status === 403) {
                navigate('/pedidos');
                return;
            };
            if (error.response.status === 404) {
                if (error.response.data?.data?.operacao_id) {
                    const message = error.response.data.message;
                    toast.error(`${message}`);
                    return setLoading(false);
                }
                toast.error('Usuário não encontrado!');
                return setLoading(false);
            };
            if (error.response.status === 409) {
                if (error.request.responseURL.includes('/usuarios')) {
                    toast.error('Usuário já cadastrado!');
                };
                if (error.request.responseURL.includes('/usuario-operacoes')) {
                    toast.error('Operação já vinculada ao usuário!');
                };
                if (error.request.responseURL.includes('/gv-rn')) {
                    toast.error('Vínculo de Gv e RN já cadastrado!');
                };
                return setLoading(false);
            };
            toast.error('Erro ao atualizar usuário!');
            setLoading(false);
        };
    };
    
    return (
        <>
        {loading ? (
            <Loading />
        ) : (
            <Form onSubmit={handleSubmit(onSubmitFunction)}>
                {!usuario_id && (
                    <div className='importar-container'>
                        <button onClick={() => setModalImportarUsuarios(true)} type='button'>
                            <ReactSVG src={ImportarSvg}/> importar arquivo
                        </button>
                    </div>
                )}
                <CheckboxContainer className='ativo'>
                    <input
                        type='checkbox'
                        id='ativo'
                        {...register('status')}
                    />
                    <label htmlFor='ativo'>Ativo</label>
                </CheckboxContainer>
                <InputContainer className='nome'>
                    <label htmlFor='nome'>Nome</label>
                    <input
                        type='text'
                        id='nome'
                        placeholder='Nome do usuário'
                        {...register('nome')}
                    />
                    {errors.nome && <span className='input-error'>{errors.nome.message}</span>}
                </InputContainer>
                <InputContainer className='usuario_id'>
                    <label htmlFor='usuario_id'>ID</label>
                    <input
                        type='text'
                        id='usuario_id'
                        placeholder='000001'
                        {...register('usuario_id', {autoComplete: 'off'})}
                    />
                    {errors.usuario_id && <span className='input-error'>{errors.usuario_id.message}</span>}
                </InputContainer>
                <InputContainer className='codigo'>
                    <label htmlFor='codigo'>Código</label>
                    <input
                        type='text'
                        id='codigo'
                        placeholder='99815014'
                        {...register('codigo')}
                    />
                    {errors.codigo && <span className='input-error'>{errors.codigo.message}</span>}
                </InputContainer>
                <InputContainer className='email'>
                    <label htmlFor='email'>E-mail</label>
                    <input
                        type='text'
                        id='email'
                        placeholder='nome@ambev.com.br'
                        {...register('email')}
                    />
                    {errors.email && <span className='input-error'>{errors.email.message}</span>}
                </InputContainer>
                <InputContainer className='senha'>
                    <label htmlFor='senha'>Senha</label>
                    <input
                        id='senha'
                        placeholder='********'
                        type='password'
                        autoComplete='new-password'
                        value={senha}
                        onChange={(event) => {
                            setSenha(event.target.value);
                        }}
                    />
                    {senhaError && <span className='input-error'>Campo obrigatório</span>}
                </InputContainer>
                <SelectContainer className='permissao'>
                    <label htmlFor='permissao'>Permissão</label>
                    <select
                        id='permissao'
                        {...register('permissao_id')}
                    >
                        <option value=''>Selecione uma permissão</option>
                        {/* <option value='7'>AC</option> */}
                        <option value='1'>Administrador</option>
                        {/* <option value='2'>Geo {'(Gerente Regional)'}</option> */}
                        <option value='3'>GC {'(Gerente Comercial)'}</option>
                        <option value='4'>GV {'(Gerente de Vendas)'}</option>
                        <option value='5'>RN {'(Representante Nacional)'}</option>
                    </select>
                    {errors.permissao_id && <span className='input-error'>{errors.permissao_id.message}</span>}
                </SelectContainer>
                {user?.permissao === 'ac' && (
                    <SelectContainer className='geo'>
                        <label htmlFor='geo'>Geo</label>
                        <select
                            id='geo'
                            value={geoSelecionada}
                            onChange={(event) => setGeoSelecionada(event.target.value)}
                        >
                            <option value=''>Selecione uma geo</option>
                            {geos.map(item => {
                                return (
                                    <option key={item.id} value={item.id}>{item.nome}</option>
                                );
                            })}
                        </select>
                        {geoError && <span className='input-error'>Campo obrigatório</span>}
                    </SelectContainer>
                )}
                <SelectContainer style={{width: '100%'}}>
                    <label>Operações</label>
                    <Select
                        className='react-select-container'
                        classNamePrefix='react-select'
                        placeholder='Selecione as operações desse usuário'
                        isMulti
                        options={operacoesFiltradas}
                        value={operacoesSelecionadas}
                        onChange={setOperacoesSelecionadas}
                    />
                </SelectContainer>
                {(usuario_id && String(getValues('permissao_id')) === '4') && (
                    <SelectContainer style={{width: '100%'}}>
                        <label htmlFor='operações'>RNs</label>
                        <Select
                            className='react-select-container'
                            classNamePrefix='react-select'
                            placeholder='Selecione os RNs desse GV'
                            isMulti
                            options={rns}
                            value={rnsSelecionados}
                            onChange={setRnsSelecionados}
                            />
                    </SelectContainer>
                )}
                {(usuario_id && String(getValues('permissao_id')) === '5') && (
                    <SelectContainer style={{width: '100%'}}>
                        <label htmlFor='operações'>GVs</label>
                        <Select
                            className='react-select-container'
                            classNamePrefix='react-select'
                            placeholder='Selecione os GVs desse RN'
                            isMulti
                            options={gvs}
                            value={gvsSelecionados}
                            onChange={setGvsSelecionados}
                        />
                    </SelectContainer>
                )}
                <div className='buttons-container'>
                    <Link to='/administrativo/usuario'>{'< Voltar'}</Link>
                    <SalvarButton 
                        onClick={() => {
                            if (user.permissao === 'ac' && !geoSelecionada) {
                                setGeoError(true);
                            } else {
                                setGeoError(false);
                            };
                            if (!senha && !usuario_id) {
                                setSenhaError(true);
                            } else {
                                setSenhaError(false);
                            }
                        }}
                        type='submit'
                    >
                        <SalvarSvg /> Salvar
                    </SalvarButton>
                </div>
                {modalImportarUsuarios && (
                    <ModalImportarUsuarios setModalOpened={setModalImportarUsuarios}/>
                )}
            </Form>
        )}
        </>
    )
}

export default UsuarioForm;
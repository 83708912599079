import { useEffect, useState } from 'react';
import { CheckboxContainer, Form, InputContainer, SalvarButton, SelectContainer } from './styles';
import { ReactComponent as SalvarSvg } from '../../../assets/salvar.svg';
import Loading from '../../Loading';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import comercialSchema from '../../../schemas/Comerciais/comercialSchema';
import { useNavigate, useParams } from 'react-router';
import { useAuth } from '../../../providers/Auth';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import { Link } from 'react-router-dom';

const ComercialForm = () => {
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm({
        resolver: yupResolver(comercialSchema)
    });

    const [geos, setGeos] = useState([]);
    
    const [geoSelecionada, setGeoSelecionada] = useState('');
    const [geoError, setGeoError] = useState(false);

    const { comercial_id } = useParams();
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const [comercialLoaded, setComercialLoaded] = useState(false);
    const [geosLoaded, setGeosLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (comercialLoaded && geosLoaded) {
            setLoading(false);
        };
    }, [comercialLoaded, geosLoaded]);

    useEffect(() => {
        if (comercial_id) {
            api.get(`/comerciais/${comercial_id}`)
                .then(response => {
                    const comercial = response.data.data.comercial;
                    reset(comercial);
                    setGeoSelecionada(comercial.geo_id);
                    setComercialLoaded(true);
                })
                .catch(error => {
                    if (error.response?.status === 401){
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response?.status === 403) {
                        navigate('/pedidos');
                        return;
                    };
                    if (error.response?.status === 404) {
                        toast.error('Comercial não encontrado!');
                        return;
                    };
                    toast.error('Erro ao buscar comercial!');
                });
        } else {
            setComercialLoaded(true);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const buscarGeos = () => {
            api.get(`/geos`)
                .then(response => {
                    setGeos(response.data.data.geos);
                    setGeosLoaded(true);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response.status === 403) {
                        navigate('/pedidos');
                        return;
                    }
                });
        };
        if (user.permissao === 'ac') {
            buscarGeos();
        } else {
            setGeosLoaded(true);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const onSubmitFunction = (data) => {
        if (user.permissao === 'ac') {
            if (!geoSelecionada) {
                setGeoError(true);
                return;
            };
        };
        const dados = {
            ...data,
            geo_id: geoSelecionada,
            status: data.status ? '1' : '0'
        };
        if (comercial_id) {
            dados.id = comercial_id;
            api.patch(`/comerciais/${comercial_id}`, dados)
                .then(response => {
                    toast.success('Comercial editado com sucesso!');
                    navigate('/administrativo/comercial');
                }).catch(error => {
                    if (error.response?.status === 401) {
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response?.status === 403) {
                        navigate('/pedidos');
                        return;
                    };
                    if (error.response?.status === 404) {
                        toast.error('Comercial não encontrado!');
                        return;
                    };
                    toast.error('Erro ao editar comercial!');
                });
        } else {
            api.post('/comerciais', dados)
                .then(response => {
                    toast.success('Comercial criado com sucesso!');
                    navigate('/administrativo/comercial');
                })
                .catch(error => {
                    if (error.response?.status === 401) {
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response?.status === 403) {
                        navigate('/pedidos');
                        return;
                    };
                    toast.error('Erro ao criar comercial!');
                });
        };
    };

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <Form onSubmit={handleSubmit(onSubmitFunction)}>
                    <CheckboxContainer className='ativo'>
                        <input
                            type='checkbox'
                            id='ativo'
                            {...register('status')}
                        />
                        <label htmlFor='ativo'>Ativo</label>
                    </CheckboxContainer>
                    <InputContainer style={{width: '100%'}}>
                        <label htmlFor='nome'>Nome</label>
                        <input 
                            type='text' 
                            id='nome' 
                            placeholder='Nome do comercial'
                            {...register('nome')}
                        />
                        {errors.nome && <span className='input-error'>{errors.nome.message}</span>}
                    </InputContainer>
                    {user.permissao === 'ac' && (
                        <SelectContainer style={{width: '100%'}}>
                            <label htmlFor='geo_id'>Geo</label>
                            <select
                                id='geo_id'
                                value={geoSelecionada}
                                onChange={e => setGeoSelecionada(e.target.value)}
                            >
                                <option value=''>Selecione uma geo</option>
                                {geos.map(item => {
                                    return (
                                        <option key={item.id} value={item.id}>{item.id} - {item.nome}</option>
                                    );
                                })}
                            </select>
                            {geoError && <span className='input-error' style={{top: '60px'}}>Campo obrigatório</span>}
                        </SelectContainer>
                    )}
                    <div className='buttons-container'>
                        <Link to='/administrativo/comercial'>{'< Voltar'}</Link>
                        <SalvarButton 
                            onClick={() => {
                                if (user.permissao === 'ac' && !geoSelecionada) {
                                    setGeoError(true);
                                    return;
                                };
                                setGeoError(false);
                            }}
                            type='submit'
                        >
                            <SalvarSvg /> Salvar
                        </SalvarButton>
                    </div>
                </Form>
            )}
        </>
    );
};

export default ComercialForm;
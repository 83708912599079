import { useState } from 'react';
import CustomModal from '../CustomModal';
import { ContentContainer, GerarQrcodeButton, ModalGerarQrcodeContainer, SelectContainer } from './styles';
import Select from 'react-select';
import { useEffect } from 'react';
import Loading from '../../Loading';
import api from '../../../services/api';
import { useAuth } from '../../../providers/Auth';
import { useNavigate } from 'react-router-dom';
import pdfMake from 'pdfmake/build/pdfmake';
import qrBrahma from '../../../assets/qr-brahma.png';
import qrBrahmaDm from '../../../assets/qr-brahma-dm.png';
import qrBud from '../../../assets/qr-bud.png';
import qrSpaten from '../../../assets/qr-spaten.png';
import qrOriginal from '../../../assets/qr-original.png';
import qrStella from '../../../assets/qr-stella.jpg';

const ModalGerarQrcode = ({setModalOpened}) => {
    const [opcaoModelo,setOpcaoModelo] = useState([])
    const [operacaoSelecionada, setOperacaoSelecionada] = useState('');
    const [pdvSelecionado, setPdvSelecionado] = useState('');
    const [modeloSelecionado, setModeloSelecionado] = useState('');

    const [operacoesOptions, setOperacoesOptions] = useState([]);
    const [pdvsOptions, setPdvsOptions] = useState([]);

    const { logout,user } = useAuth();
    const navigate = useNavigate();

    const [operacoesLoaded, setOperacoesLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pdvsLoading, setPdvsLoading] = useState(false);

    useEffect(() => {
         //opcão da quantidade de impressos
         if(user.geo_id === 1){
            setOpcaoModelo([
                {id:1,valor:"brahma",nome:"Brahma"},
                {id:2,valor:"brahma duplo malte",nome:"Brahma Duplo Malte"},
                {id:4,valor:"budweiser",nome:"Budweiser"}
            ])
        }
        else if(user.geo_id !== 1){
            setOpcaoModelo([
                {id:1,valor:"brahma",nome:"Brahma"},
                {id:2,valor:"brahma duplo malte",nome:"Brahma Duplo Malte"},
                {id:3,valor:"spaten",nome:"Spaten"},
                {id:4,valor:"budweiser",nome:"Budweiser"},
                {id:5,valor:"original",nome:"Original"},
                {id:6,valor:"stella",nome:"Stella"}
            ])
        }

        const buscarOperacoes = () => {
            api.get('/operacoes')
                .then((response) => {
                    const operacoesFiltradas = response.data.data.operacoes.filter((operacao) => operacao.status === 1);
                    const operacoesOptions = operacoesFiltradas.map((operacao) => {
                        return {
                            value: operacao.id,
                            label: operacao.nome
                        }
                    });
                    setOperacoesOptions(operacoesOptions);
                    setOperacoesLoaded(true);
                }).catch((error) => {
                    if (error.response?.status === 401){
                        logout();
                        navigate('/');
                    };
                });
        };
        buscarOperacoes();
    }, []);

    useEffect(() => {
        if (operacoesLoaded) {
            setLoading(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operacoesLoaded]);

    const convertImageToBase64 = (imagePath) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.crossOrigin = 'anonymous';
          img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/jpeg');
            resolve(dataURL);
          };
          img.onerror = reject;
          img.src = imagePath;
        });
      }

    useEffect(() => {
        const buscarPdvs = () => {
            setPdvsLoading(true);
            api.get(`/pdvs?operacao_id=${operacaoSelecionada.value}`)
                .then((response) => {
                    const pdvsFiltrados = response.data.data.pdvs.filter((pdv) => pdv.link && pdv.status === 1);
                    const pdvsOptions = pdvsFiltrados.map((pdv) => {
                        return {
                            value: pdv.id,
                            label: pdv.codigo + ' - '+ pdv.nome,
                            link: pdv.link,
                        };
                    });
                    setPdvsOptions(pdvsOptions);
                    setPdvsLoading(false);
                }).catch((error) => {
                    if (error.response?.status === 401){
                        logout();
                        navigate('/');
                    };
                });
        };
        if (operacaoSelecionada) {
            buscarPdvs();
        };
    }, [operacaoSelecionada]);

    const handleGeraQrcode = async () => {
        const protocolo = window.location.protocol;
        const dominio = window.location.href.split('/')[2];
        const url = `${protocolo}//${dominio}${pdvSelecionado.link}`;

        const pageSize = {
            width: 595.35,
            height: 841.995,
        };
        const QrAbsolutePosition = {
            x: 0,
            y: 0,
        };
        let image = '';
        if (modeloSelecionado === 'brahma') {
            image = await convertImageToBase64(qrBrahma);
            QrAbsolutePosition.x = 43;
            QrAbsolutePosition.y = 100;
        };
        if (modeloSelecionado === 'brahma duplo malte') {
            image = await convertImageToBase64(qrBrahmaDm);
            QrAbsolutePosition.x = 43;
            QrAbsolutePosition.y = 80;
        };
        if (modeloSelecionado === 'budweiser') {
            image = await convertImageToBase64(qrBud);
            QrAbsolutePosition.x = 42;
            QrAbsolutePosition.y = 84;
        };
        if (modeloSelecionado === 'spaten') {
            image = await convertImageToBase64(qrSpaten);
            QrAbsolutePosition.x = 42;
            QrAbsolutePosition.y = 83;
        };
        if (modeloSelecionado === 'original') {
            image = await convertImageToBase64(qrOriginal);
            QrAbsolutePosition.x = 42;
            QrAbsolutePosition.y = 92;
        };
        if (modeloSelecionado === 'stella') {
            image = await convertImageToBase64(qrStella);
            QrAbsolutePosition.x = 42;
            QrAbsolutePosition.y = 91;
        };

        const content = [
            [
                { 
                    image,
                    width: pageSize.width / 3,
                    height: pageSize.height / 3.3,
                    absolutePosition: { 
                        x: 0,
                        y: 0,
                    },
                },
                { 
                    qr: url, 
                    fit: '130',
                    eccLevel: 'H', 
                    absolutePosition: QrAbsolutePosition,
                    foreground: modeloSelecionado === 'brahma duplo malte' ? '#671425' : 'black',
                    background: modeloSelecionado === 'brahma duplo malte' ? '#FDF5DE' : 'white',
                }
            ]
        ];

        const docDefinition = {
            pageSize,
            content,
        };
        pdfMake.createPdf(docDefinition).open()
    };

    return (
        <ModalGerarQrcodeContainer>
             <CustomModal setModalOpened={setModalOpened}>
                {loading ? (
                    <Loading />
                ) : (
                    <ContentContainer>
                         <SelectContainer className='operacao'>
                            <label htmlFor='operacao'>Operação</label>
                            <Select
                                options={operacoesOptions}
                                className='react-select-container'
                                classNamePrefix='react-select'
                                value={operacaoSelecionada}
                                placeholder='Selecione a operação'
                                onChange={(value) => {
                                    setOperacaoSelecionada(value);
                                }}
                            /> 
                        </SelectContainer>
                        <SelectContainer>
                            <label htmlFor='pdv'>PDV</label>
                            <Select
                                options={pdvsOptions}
                                onChange={(e) => setPdvSelecionado(e)}
                                className='react-select-container'
                                classNamePrefix='react-select'
                                isLoading={pdvsLoading}
                                isDisabled={!operacaoSelecionada}
                                placeholder={operacaoSelecionada ? 'Selecione o PDV' : 'Selecione uma operação'}
                            /> 
                        </SelectContainer>
                        <SelectContainer>
                            <label htmlFor='modelo'>Modelo do QR Code</label>
                            <select 
                                id='modelo'
                                value={modeloSelecionado}
                                onChange={(e) => setModeloSelecionado(e.target.value)}
                            >
                                <option value=''>Selecione a marca de customização</option>
                                {opcaoModelo.map((item)=> <option key={item.id} value={item.valor}>{item.nome}</option>)}
                            </select>
                        </SelectContainer>
                        <GerarQrcodeButton 
                            onClick={async () => await handleGeraQrcode()}
                            disabled={!pdvSelecionado || !modeloSelecionado}
                        >
                            Gerar QR Code
                        </GerarQrcodeButton>
                    </ContentContainer>
                )}
            </CustomModal>
        </ModalGerarQrcodeContainer>
    );
};

export default ModalGerarQrcode;